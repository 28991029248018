










































import Vue from 'vue'
import { validatePass } from '@/assets/js/verification'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class setPassword extends Vue {
  @Prop(Number) readonly type: number | undefined
  @Prop({ default: {} }) readonly formData!: any
  @Watch('ruleForm.pass')
  onChanged(val: string, oldVal: string): void {
    if (val !== oldVal && this.ruleForm.pass2) {
      ;(this.$refs['ruleForm'] as vForm)?.validate((valid: any) => {
        if (!valid) return false
      })
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  validatePassAgain(_rule: any, value: string, callback: any): void {
    if (value === '') {
      callback(new Error('请再次输入密码'))
    } else if (value !== this.ruleForm.pass) {
      callback(new Error('两次密码输入不一致'))
    } else {
      callback()
    }
  }
  eyeLook1 = false
  eyeLook2 = false
  btnLoading = false
  title = '设置密码'
  ruleForm = {
    pass: '',
    pass2: ''
  }
  rules = {
    pass: [{ validator: validatePass, trigger: 'blur' }],
    pass2: [{ validator: this.validatePassAgain, trigger: 'blur' }]
  }
  submitForm(): void {
    ;(this.$refs['ruleForm'] as vForm)?.validate((valid: any) => {
      if (valid) {
        this.btnLoading = true
        let row = {
          account: this.formData.phone,
          code: this.formData.smsCode,
          password1: this.ruleForm.pass,
          password2: this.ruleForm.pass2,
          code_type: 2
        }
        let type = this.type || 0
        let api
        if (type) {
          // 找回密码
          api = this.$api.reset_password
        } else {
          // 注册--设置密码
          api = this.$api.register
        }
        this.$http
          .post(api, row)
          .then((res: any) => {
            const msg = res.data.meta.message
            if (res.data.meta.status_code === 200) {
              if (type) {
                this.$message.success('密码已重置，请重新登录太火鸟')
                this.$router.push({ name: 'login' })
              } else {
                const token = res.data.data.ticket
                // 设置cookie并跳转页面
                this.$store.commit('setToken', token)
                this.$message.success(msg)
              }
              this.btnLoading = false
            } else {
              this.btnLoading = false
              this.$message.error(res.data.meta.message)
            }
          })
          .catch((error: any) => {
            this.btnLoading = false
            console.log('err', error)
          })
      } else {
        return false
      }
    })
  }
  // 点击眼睛
  clickEye(type: number): void {
    if (type === 1) {
      this.eyeLook1 = !this.eyeLook1
    } else if (type === 2) {
      this.eyeLook2 = !this.eyeLook2
    }
  }
  created(): void {
    if (this.type) this.title = '设置新密码'
  }
}
