interface checkResult {
  status: number
  message: string
}

interface validateParams {
  (rule: null, value: string, callback: (error?: Error) => Error): void
}
import { phoneRegex } from '@/assets/js/common'
// 验证手机号
export function checkPhone(value: string): checkResult {
  const row: checkResult = {
    status: 0,
    message: ''
  }
  if (!value) {
    row.message = '请输入正确的手机号'
  } else {
    if (!Number.isInteger(Number(value))) {
      row.message = '请输入正确的手机号'
    } else {
      const len = value.toString().length
      if (len === 11) {
        if (phoneRegex.test(value)) {
          row.status = 1
          row.message = '验证通过'
        } else {
          row.message = '请输入正确的手机号'
        }
      } else {
        row.message = '请输入正确的手机号'
      }
    }
  }
  return row
}
// from验证手机号
export const validatePhone: validateParams = function (rule, value, callback) {
  const row = checkPhone(value)
  if (row.status) {
    callback()
  } else {
    callback(new Error(row.message))
  }
}
export const validateSms: validateParams = function (rule, value, callback) {
  if (!value) {
    callback(new Error('请输入正确的验证码'))
  } else {
    if (!Number.isInteger(Number(value))) {
      callback(new Error('请输入正确的验证码'))
    } else {
      const len = value.toString().length
      if (len === 4) {
        callback()
      } else {
        callback(new Error('请输入正确的验证码'))
      }
    }
  }
}
export const validatePass: validateParams = function (rule, value, callback) {
  if (!value) {
    callback(new Error('密码至少 8 个字符，不可包含空格或表情符号'))
  } else {
    const len = value.toString().length
    if (len >= 8) {
      if (/(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.,~!@#$%^&*()]{2,16}$/.test(value)) {
        callback()
      } else {
        callback(new Error('密码至少 8 个字符，不可包含空格或表情符号'))
      }
    } else {
      callback(new Error('密码至少 8 个字符，不可包含空格或表情符号'))
    }
  }
}

export const validatePassAgain = function (newVal: string): validateParams {
  return function (rule, value, callback) {
    if (value === '') {
      callback(new Error('确认密码错误'))
    } else if (value !== newVal) {
      callback(new Error('确认密码错误'))
    } else {
      callback()
    }
  }
}
